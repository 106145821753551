import React from 'react';
// import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Routes,
    Route,
    Link,
    Navigate
  } from "react-router-dom";

  import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/material';

  
  

  const NextStep =({user})=>{

    const [activeStep, setActiveStep]= useState(0);

    const stepStyle = {
  
    // backgroundColor:'grey',
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "#2b2b2b"
      },
      },

      "& .Mui-completed":{
        "&.MuiStepIcon-root": {
          color: "#2b2b2b"
        },
      }
    };

    const customConfig = {
      headers: {
      'Content-Type': 'application/json'
      }
  };

  // const c = useStyles();
    const TestR =async ()=>{

      console.log(user)
      const veri_check= await (user.verify_status==='verified')?setActiveStep(1):'';
      const pm_check= await (user.pm_type!=null)?setActiveStep(2):'';


      // const items= await axios('/result', customConfig)
      // const resi= await items.data;
      // const veri_check= await (resi.verify_status==='verified')?setActiveStep(1):'';
      // const pm_check= await (resi.pm_type!=null)?setActiveStep(2):'';
      console.log(activeStep);
    
    
    
    }
 
    useEffect(()=>{
      
      TestR();
        },[])

  return (
    <div>
<Stepper activeStep={activeStep} sx={stepStyle}>
  <Step>
    <StepLabel>Identity Check</StepLabel>
  </Step>
  <Step>
    <StepLabel>Bank Details</StepLabel>
  </Step>
  <Step>
    <StepLabel>Terms & Conditions</StepLabel>
  </Step>
</Stepper>

</div>
  )
}

export default NextStep;