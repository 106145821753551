import React, {useEffect, useState} from 'react';
import { Navigate, redirect} from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Spinner from 'react-bootstrap/Spinner';
import NextStep from './Stepper';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';

function Profile({user}) {

  let [color, setColor] = useState("#006666");
  const [userVerify, setUserVerify]= useState(0);
  const [last4, setLast4]= useState("");
  const [pmType, setPmType]= useState(0);
  const [loading, setLoading]= useState(true);
  const [step, setStep]= useState("");
  const [showLoader1, setShowLoader1] = useState(false);
  const [showLoader2, setShowLoader2] = useState(false);
  const [showLoader3, setShowLoader3] = useState(false);
  const [showLoader4, setShowLoader4] = useState(false);

  let stripe;

  const customConfig = {
    headers: {
    'Content-Type': 'application/json',
    'username': user.stripe_id

    }
};

  const Results =async ()=>{


    const items= await axios.get("api/user");
    const resi= await items.data
    // const saveStep= await setStep(resi)
    console.log(resi);
    const verify_check= await resi.verify_status==='verified'?setUserVerify(1):setUserVerify(0)
    const pm_check= await (resi.pm_type==='card'||resi.pm_type==='bacs_debit')?setPmType(2):setPmType(0);
    
  //  const stopLoading= await setLoading(false)
    
  
    console.log(userVerify);  
    console.log(pmType)
  
  }

  const loadKey= async()=>{
   
    stripe = await loadStripe('pk_test_jaIMIqgeBkQ4SlqiMOtIcFlU00yWyiux9P');
    console.log(stripe)

  }

  const saveCard=async(e)=>{
    e.preventDefault();

     
    setShowLoader2(true);

    setTimeout(() => setShowLoader2(false), 7000);

    stripe = await loadStripe('pk_test_jaIMIqgeBkQ4SlqiMOtIcFlU00yWyiux9P');
  
  axios('/api/card-method', customConfig).then((response)=>stripe
  .redirectToCheckout({
    sessionId: response.data.sessionId,
  }));
  

  };


  const customerProfile=async(e)=>{
    e.preventDefault();
  
     
    setShowLoader4(true);

    setTimeout(() => setShowLoader4(false), 7000);
    
    stripe = await loadStripe('pk_test_jaIMIqgeBkQ4SlqiMOtIcFlU00yWyiux9P');
  
  axios('/api/customerprofile', customConfig).then((response)=>(response.data)).then((data)=>window.location.href = data.url)
  
  
  };

  const StartVerification=async (e)=>{

   
 
    setShowLoader1(true);

    setTimeout(() => setShowLoader1(false), 7000);

    // try {



         const response = await axios('api/create-verification-session',customConfig);
         const session = await response.data;
         console.log(session);


         window.location.href = session;

        // Open the modal on the client.

}

const directDebit=async(e)=>{

  

  e.preventDefault();

   
  setShowLoader3(true);

  setTimeout(() => setShowLoader3(false), 7000);
  stripe = await loadStripe('pk_test_jaIMIqgeBkQ4SlqiMOtIcFlU00yWyiux9P');

axios('/api/dd-method', customConfig).then((response)=>stripe
.redirectToCheckout({
  sessionId: response.data.sessionId,
}));

}

useEffect(()=>{
    loadKey();
    Results();
   
      },[])
    

  if (!localStorage.getItem('token')){
   return <Navigate to={'/register'}/>
  }

  return ( 

    // !loading?
    user.name?
    // localStorage.getItem('token')? 
    // <div className="outer">
    <div className="outer"><div className="col-10 mx-auto my-5"> 
    <div className="text-center">{userVerify===0?<h1>Verify your identity to book</h1>:<p className='disapear'></p>}
    {(userVerify===1&&pmType===0)?<h1>Your payment details are required</h1>:<p className='disapear'></p>}
   {(userVerify===1&&pmType===2)?<h1>Onboarding now complete</h1>:<p className='disapear'></p>}    
            {/* <h4>Get ready to take a photo of your ID and a selfie</h4> */}
            </div><br></br>
    
    <NextStep user={user}/></div>
   
    {/* <div className="inner py-2 bg-white col-12 col-md-8 col-lg-6 mx-auto "> */}

    <div className="align-bottom py-2 col-12 col-md-8 col-lg-8 mx-auto ">

<div className="row ">
 
{userVerify===0?<div className="col text-center"><button onClick={StartVerification} id="verify-button" className="btn bg-dpblack navShadow text-white btn-lg btn-block">{!showLoader1?'Verify':'Loading...'}</button></div>:<p className='disapear'></p>}
{(userVerify===1&&pmType===0)?<div className="col text-center"><form onSubmit={saveCard} ><button type="submit" class="btn bg-dpblack navShadow text-white btn-lg btn-block">{!showLoader2?'Save Card':'Loading...'}</button></form></div>:<p className='disapear'></p>}
{(userVerify===1&&pmType===0)?<div className="col text-center"><form onSubmit={directDebit} ><button type="submit" class="btn bg-dpblack navShadow text-white btn-lg btn-block">{!showLoader3?'Direct Debit':'Loading...'}</button></form></div>:<p className='disapear'></p>}
<div className="col  text-center"><form onSubmit={customerProfile} >
      <button type="submit" className="btn bg-dpblack navShadow text-white btn-lg btn-block">{!showLoader4?'My Portal':'Loading...'}</button>
    </form></div></div>
   
{/* <h3 className="center">User Profile</h3>

<ul class="list-group mb-5">
  <li class="list-group-item">Name: {user.name}</li>
  <li class="list-group-item">Email: {user.email}</li>
  
</ul> */}

</div>

</div>

// </div>
// :<div>Loading</div>
// :<Navigate to={'/login'}/>

  : 
 <Spinner animation="border" variant="secondary" className='centre'/> 



    
  
  )
}
// }
export default Profile