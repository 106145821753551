import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link, Navigate} from 'react-router-dom';


const Reset = () => {
    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword]= useState("");
    const [message, setMessage] = useState("");


    const formSubmit=(e)=>{
        e.preventDefault();
        axios.post("/resetpassword", {
            token:token,
            email:email,
            password:password,
            password_confirmation:confirmPassword,
           }).then((response)=>{
            setMessage(response.data.message)
            // thisUser(response.data);
           
            console.log(response.data)
            document.getElementById("resetForm").reset();
            
            // setStorage(response.data.token);
              ;}).catch((error) => {
              // here you will have access to error.response,
              setMessage(error.response.data.message)
              console.log(error.response.data.message)
          });
    
    };

    let error="";
  
  if(message){

    error= (<div>
      <div className='alert alert-danger' role="alert">
      {message}
      </div>
    </div>)
  } 

  return (
    <div className="outer">
    
    
    <div className="inner py-2 bg-white col-12 col-md-8 col-lg-6 mx-auto ">
<form onSubmit={formSubmit} id="resetForm">

<h3 className="center">Reset Password</h3>
{error}

<div className="form-group">
    <label>Pin Code</label>
    <input type="text" name="token" className="form-control" onChange={(e)=>setToken(e.target.value)} placeholder="Enter Pin" required />
</div>

<div className="form-group">
    <label>Email</label>
    <input type="email" name="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email" required/>
</div>

<div className="form-group">
    <label>New Password</label>
    <input type="password" name="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Enter password" required/>
</div>

<div className="form-group">
    <label>Confirm Password</label>
    <input type="password" name="password_confirmation" className="form-control" onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Enter password" required/>
</div>
<div><p></p></div>

<button type="submit" className="btn btn-dark btn-lg btn-block">Reset</button>
<p className="forgot-password text-right">
    Have an account? <Link to="/login">Log In</Link>
</p>
</form>
</div>
</div>
  )
}

export default Reset