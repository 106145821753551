import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link, Navigate} from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";


function Register({setUser, user, getUser}) {
    const [name, setName] = useState("");
    const [surname, setSurname]= useState("");
    const [street, setStreet]= useState("");
    const [houseNo, setHouseNo]= useState("");
    const [city, setCity]= useState("");
    const [postCode, setPostCode]= useState("");
    const [country, setCountry]= useState("GB");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword]= useState("");
    const [spam, setSpam]= useState(null);
    const [loggedIn, setLoggedIn]= useState(false);
  
    const [message, setMessage] = useState(true);
    const [rand3, setRand3]= useState(Math.floor(Math.random() * 5));
    const [rand4, setRand4]= useState(Math.floor(Math.random() * 5));
    const [regTotal, setRegTotal]= useState(rand3+rand4);
    const [activeReg, setActiveReg]= useState(true)
    const [showLoader6, setShowLoader6] = useState(false);

    function handleChange(e) {
        if(e.target.value==regTotal){
        setActiveReg(false)
        }else{setActiveReg(true)}
  
     
      }

      function handleCounrty(e){

        setCountry(e.target.value);

      }

    const formSubmit=(e)=>{
        e.preventDefault();

        setShowLoader6(true);

        setTimeout(() => setShowLoader6(false), 4000);
        axios.post("api/register", {
            name:name,
            surname: surname,
            street: street,
            houseNo: houseNo,
            city: city,
            postCode: postCode,
            country: country,
            email:email,
            password:password,
            password_confirmation:confirmPassword,
            spam:spam
           }).then((response)=>{
            localStorage.setItem('token',response.data.token);
            // thisUser(response.data);
          
            console.log(response.data)
            setUser(response.data.user)
            // setStorage(response.data.token);
              ;}).catch((error) => {
              // here you will have access to error.response,
              console.log(error.response)
          });
    
    };

    // useEffect(()=>{
    
    //     getUser();
       
    //       },[]);


         


          if(localStorage.getItem("token")) {
            return <Navigate to={'/'}/>
        }else{

  return (
    <div className="outer">
    
    
    <div className="inner py-2 bg-white col-12 col-md-8 col-lg-6 mx-auto ">
<form onSubmit={formSubmit}>

<h3 className="center">Register</h3>

<div className="row">
<div className="form-group col-md-6">
    <label>First Name</label>
    <input type="text" name="name" className="form-control" onChange={(e)=>setName(e.target.value)} placeholder="Enter name" required />
</div>
<div className="form-group col-md-6">
    <label>Surname</label>
    <input type="text" name="surname" className="form-control" onChange={(e)=>setSurname(e.target.value)} placeholder="Surname" required />
</div>
</div>


<div className="form-group">
<label>Address</label>
<input type="text" name="street" className="form-control"  onChange={(e)=>setStreet(e.target.value)} placeholder="Street" required />
<p></p>
<input type="text" name="houseNo" className="form-control"  onChange={(e)=>setHouseNo(e.target.value)} placeholder="House No." required />
<p></p>
<input type="text" name="city" className="form-control" onChange={(e)=>setCity(e.target.value)} placeholder="City" required />
<p></p>
<input type="text" name="postCode" className="form-control" onChange={(e)=>setPostCode(e.target.value)} placeholder="Post Code" required />
<p></p>
{/* <input type="text" name="Country" className="form-control"  placeholder="Country" required /> */}
<select id="countries" value={country} name="countries" className='form-select' onChange={handleCounrty}>
<option value="GB">United Kingdom</option>
<option value="US">United States</option>
<option value="AF">Afghanistan</option>
<option value="AX">Åland Islands</option>
<option value="AL">Albania</option>
<option value="DZ">Algeria</option>
<option value="AS">American Samoa</option>
<option value="AD">Andorra</option>
<option value="AO">Angola</option>
<option value="AI">Anguilla</option>
<option value="AQ">Antarctica</option>
<option value="AG">Antigua and Barbuda</option>
<option value="AR">Argentina</option>
<option value="AM">Armenia</option>
<option value="AW">Aruba</option>
<option value="AU">Australia</option>
<option value="AT">Austria</option>
<option value="AZ">Azerbaijan</option>
<option value="BS">Bahamas</option>
<option value="BH">Bahrain</option>
<option value="BD">Bangladesh</option>
<option value="BB">Barbados</option>
<option value="BY">Belarus</option>
<option value="BE">Belgium</option>
<option value="BZ">Belize</option>
<option value="BJ">Benin</option>
<option value="BM">Bermuda</option>
<option value="BT">Bhutan</option>
<option value="BO">Bolivia</option>
<option value="BA">Bosnia and Herzegovina</option>
<option value="BW">Botswana</option>
<option value="BV">Bouvet Island</option>
<option value="BR">Brazil</option>
<option value="IO">British Indian Ocean Territory</option>
<option value="BN">Brunei Darussalam</option>
<option value="BG">Bulgaria</option>
<option value="BF">Burkina Faso</option>
<option value="BI">Burundi</option>
<option value="KH">Cambodia</option>
<option value="CM">Cameroon</option>
<option value="CA">Canada</option>
<option value="CV">Cape Verde</option>
<option value="KY">Cayman Islands</option>
<option value="CF">Central African Republic</option>
<option value="TD">Chad</option>
<option value="CL">Chile</option>
<option value="CN">China</option>
<option value="CX">Christmas Island</option>
<option value="CC">Cocos (Keeling) Islands</option>
<option value="CO">Colombia</option>
<option value="KM">Comoros</option>
<option value="CG">Congo</option>
<option value="CD">Congo, The Democratic Republic of The</option>
<option value="CK">Cook Islands</option>
<option value="CR">Costa Rica</option>
<option value="CI">Cote D'ivoire</option>
<option value="HR">Croatia</option>
<option value="CU">Cuba</option>
<option value="CY">Cyprus</option>
<option value="CZ">Czechia</option>
<option value="DK">Denmark</option>
<option value="DJ">Djibouti</option>
<option value="DM">Dominica</option>
<option value="DO">Dominican Republic</option>
<option value="EC">Ecuador</option>
<option value="EG">Egypt</option>
<option value="SV">El Salvador</option>
<option value="GQ">Equatorial Guinea</option>
<option value="ER">Eritrea</option>
<option value="EE">Estonia</option>
<option value="ET">Ethiopia</option>
<option value="FK">Falkland Islands (Malvinas)</option>
<option value="FO">Faroe Islands</option>
<option value="FJ">Fiji</option>
<option value="FI">Finland</option>
<option value="FR">France</option>
<option value="GF">French Guiana</option>
<option value="PF">French Polynesia</option>
<option value="TF">French Southern Territories</option>
<option value="GA">Gabon</option>
<option value="GM">Gambia</option>
<option value="GE">Georgia</option>
<option value="DE">Germany</option>
<option value="GH">Ghana</option>
<option value="GI">Gibraltar</option>
<option value="GR">Greece</option>
<option value="GL">Greenland</option>
<option value="GD">Grenada</option>
<option value="GP">Guadeloupe</option>
<option value="GU">Guam</option>
<option value="GT">Guatemala</option>
<option value="GG">Guernsey</option>
<option value="GN">Guinea</option>
<option value="GW">Guinea-bissau</option>
<option value="GY">Guyana</option>
<option value="HT">Haiti</option>
<option value="HM">Heard Island and Mcdonald Islands</option>
<option value="VA">Holy See (Vatican City State)</option>
<option value="HN">Honduras</option>
<option value="HK">Hong Kong</option>
<option value="HU">Hungary</option>
<option value="IS">Iceland</option>
<option value="IN">India</option>
<option value="ID">Indonesia</option>
<option value="IR">Iran, Islamic Republic of</option>
<option value="IQ">Iraq</option>
<option value="IE">Ireland</option>
<option value="IM">Isle of Man</option>
<option value="IL">Israel</option>
<option value="IT">Italy</option>
<option value="JM">Jamaica</option>
<option value="JP">Japan</option>
<option value="JE">Jersey</option>
<option value="JO">Jordan</option>
<option value="KZ">Kazakhstan</option>
<option value="KE">Kenya</option>
<option value="KI">Kiribati</option>
<option value="KP">Korea, Democratic People's Republic of</option>
<option value="KR">Korea, Republic of</option>
<option value="KW">Kuwait</option>
<option value="KG">Kyrgyzstan</option>
<option value="LA">Lao People's Democratic Republic</option>
<option value="LV">Latvia</option>
<option value="LB">Lebanon</option>
<option value="LS">Lesotho</option>
<option value="LR">Liberia</option>
<option value="LY">Libyan Arab Jamahiriya</option>
<option value="LI">Liechtenstein</option>
<option value="LT">Lithuania</option>
<option value="LU">Luxembourg</option>
<option value="MO">Macao</option>
<option value="MK">Macedonia, The Former Yugoslav Republic of</option>
<option value="MG">Madagascar</option>
<option value="MW">Malawi</option>
<option value="MY">Malaysia</option>
<option value="MV">Maldives</option>
<option value="ML">Mali</option>
<option value="MT">Malta</option>
<option value="MH">Marshall Islands</option>
<option value="MQ">Martinique</option>
<option value="MR">Mauritania</option>
<option value="MU">Mauritius</option>
<option value="YT">Mayotte</option>
<option value="MX">Mexico</option>
<option value="FM">Micronesia, Federated States of</option>
<option value="MD">Moldova, Republic of</option>
<option value="MC">Monaco</option>
<option value="MN">Mongolia</option>
<option value="ME">Montenegro</option>
<option value="MS">Montserrat</option>
<option value="MA">Morocco</option>
<option value="MZ">Mozambique</option>
<option value="MM">Myanmar</option>
<option value="NA">Namibia</option>
<option value="NR">Nauru</option>
<option value="NP">Nepal</option>
<option value="NL">Netherlands</option>
<option value="AN">Netherlands Antilles</option>
<option value="NC">New Caledonia</option>
<option value="NZ">New Zealand</option>
<option value="NI">Nicaragua</option>
<option value="NE">Niger</option>
<option value="NG">Nigeria</option>
<option value="NU">Niue</option>
<option value="NF">Norfolk Island</option>
<option value="MP">Northern Mariana Islands</option>
<option value="NO">Norway</option>
<option value="OM">Oman</option>
<option value="PK">Pakistan</option>
<option value="PW">Palau</option>
<option value="PS">Palestinian Territory, Occupied</option>
<option value="PA">Panama</option>
<option value="PG">Papua New Guinea</option>
<option value="PY">Paraguay</option>
<option value="PE">Peru</option>
<option value="PH">Philippines</option>
<option value="PN">Pitcairn</option>
<option value="PL">Poland</option>
<option value="PT">Portugal</option>
<option value="PR">Puerto Rico</option>
<option value="QA">Qatar</option>
<option value="RE">Reunion</option>
<option value="RO">Romania</option>
<option value="RU">Russian Federation</option>
<option value="RW">Rwanda</option>
<option value="SH">Saint Helena</option>
<option value="KN">Saint Kitts and Nevis</option>
<option value="LC">Saint Lucia</option>
<option value="PM">Saint Pierre and Miquelon</option>
<option value="VC">Saint Vincent and The Grenadines</option>
<option value="WS">Samoa</option>
<option value="SM">San Marino</option>
<option value="ST">Sao Tome and Principe</option>
<option value="SA">Saudi Arabia</option>
<option value="SN">Senegal</option>
<option value="RS">Serbia</option>
<option value="SC">Seychelles</option>
<option value="SL">Sierra Leone</option>
<option value="SG">Singapore</option>
<option value="SK">Slovakia</option>
<option value="SI">Slovenia</option>
<option value="SB">Solomon Islands</option>
<option value="SO">Somalia</option>
<option value="ZA">South Africa</option>
<option value="GS">South Georgia and The South Sandwich Islands</option>
<option value="ES">Spain</option>
<option value="LK">Sri Lanka</option>
<option value="SD">Sudan</option>
<option value="SR">Suriname</option>
<option value="SJ">Svalbard and Jan Mayen</option>
<option value="SZ">Swaziland</option>
<option value="SE">Sweden</option>
<option value="CH">Switzerland</option>
<option value="SY">Syrian Arab Republic</option>
<option value="TW">Taiwan, Province of China</option>
<option value="TJ">Tajikistan</option>
<option value="TZ">Tanzania, United Republic of</option>
<option value="TH">Thailand</option>
<option value="TL">Timor-leste</option>
<option value="TG">Togo</option>
<option value="TK">Tokelau</option>
<option value="TO">Tonga</option>
<option value="TT">Trinidad and Tobago</option>
<option value="TN">Tunisia</option>
<option value="TR">Turkey</option>
<option value="TM">Turkmenistan</option>
<option value="TC">Turks and Caicos Islands</option>
<option value="TV">Tuvalu</option>
<option value="UG">Uganda</option>
<option value="UA">Ukraine</option>
<option value="AE">United Arab Emirates</option>

<option value="UM">United States Minor Outlying Islands</option>
<option value="UY">Uruguay</option>
<option value="UZ">Uzbekistan</option>
<option value="VU">Vanuatu</option>
<option value="VE">Venezuela</option>
<option value="VN">Viet Nam</option>
<option value="VG">Virgin Islands, British</option>
<option value="VI">Virgin Islands, U.S.</option>
<option value="WF">Wallis and Futuna</option>
<option value="EH">Western Sahara</option>
<option value="YE">Yemen</option>
<option value="ZM">Zambia</option>
<option value="ZW">Zimbabwe</option>
</select>
    </div>

<div className="form-group">
    <label>Email</label>
    <input type="email" name="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="Enter email" required/>
</div>

<div className="form-group">
    <label>Password</label>
    <input type="password" name="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Enter password" required/>
</div>

<div className="form-group">
    <label>Confirm Password</label>
    <input type="password" name="password_confirmation" className="form-control" onChange={(e)=>setConfirmPassword(e.target.value)} placeholder="Enter password" required/>
</div>
<div className="form-group spam">
    <label>Spam</label>
    <input type="text" name="spam" className="form-control" onChange={(e)=>setSpam(e.target.value)} placeholder="text"/>
</div>
<div className="form-group">
      <label>Captcha </label>
      <input type="number" className="form-control" name="captcha"  onChange={handleChange} placeholder={(`${rand3} + ${rand4} =`)} required/>
  </div>
<div><p></p></div>

<button type="submit" className="btn btn-dark btn-lg btn-block" disabled={activeReg}>{ !showLoader6?'Register':'Loading...'}</button>
<p className="forgot-password text-right">
    Have an account? <Link to="/login">Log In</Link>
</p>
</form>
</div>
</div>

  
  )}
}

export default Register