import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav';
import Login from './components/Login';
import Header from './components/Header';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <div className="App">
 <Router>
<Header/>
</Router>
        </div>
       
    
    
  );
}

export default App;
