import React ,{useState} from 'react';
import { Link } from "react-router-dom";
// import logo from './../David_Phillips_Transparent.png';
import logo from '../David_Phillips_Transparent.png';
import logo2 from '../logo.svg'




const Nav = ({ setUser, setLoading}) => {

  const [loggedOut, setLoggedOut]= useState('');

  const logOut=()=>{

    localStorage.clear();
    setLoading(false)
    setUser(null);
  }

  let buttons;
  let profile; 

  if(localStorage.getItem('token')){
    buttons= (<div>
<Link class="nav-link text-light" to="/" onClick={logOut}>Logout</Link>

    </div>)
    profile=(<div>
      <Link class="nav-link text-light" to="/profile">Profile</Link>
      
          </div>)
  }else{
    buttons= (<div >
 <ul class='navbar-nav '>
        <li class="nav-item">
        

            <Link class="nav-link text-light" to="/login">Login</Link>
          </li>
          <li class="nav-item">
          
            <Link class="nav-link text-light" to="/register">Register</Link>
          </li>
        </ul>

    </div>)
  }
  return (
    <div>
      

<nav class="navbar nav-custom navbar-expand-lg bg-dpblack navShadow">
  <div class="container-fluid bg-secondary ">
    {/* <a class="navbar-brand text-light oncode" href="#">On Code &#10094;/&#10095;</a> */}
    {/* <img src={logo2} className='dpLogo'></img> */}
    {/* <img src='https://www.svgrepo.com/show/80293/online.svg' className='dpLogo'></img> */}
    <img src="https://i.ibb.co/1sYb0pS/David-Phillips-Transparent.png" alt="David-Phillips-Transparent" border="0" className='dpLogo'></img>
    <button class="navbar-toggler text-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon text-light"></span>
    </button>
    <div class="collapse navbar-collapse text-light" id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-light">
        <li class="nav-item ">
         
          <Link class="nav-link text-light active" to="/">Home</Link>
        </li>
        <li class="nav-item">
          {/* <Link className="nav-link text-light " to="/profile">Profile</Link> */}
          {profile}
        </li>
       
      </ul>
      <span class="navbar-text">
      {/* <ul class='navbar-nav'>
        <li class="nav-item">
        

            <Link class="nav-link text-light" to="/login">Login</Link>
          </li>
          <li class="nav-item">
          
            <Link class="nav-link text-light" to="/register">Register</Link>
          </li>
        </ul> */}

        {buttons}
      </span>
    </div>
  </div>
</nav>
  </div>
  )
}

export default Nav